import React, { useState, useEffect } from "react";
import "../scss/BlogPage3All.scss";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import bof from './bof.png';
import { Helmet } from "react-helmet";
import arrow from './arrow_down.png';


// const AccordionDropdown = () => {
//     const [activeIndexes, setActiveIndexes] = useState([]);
//     const [expandedSection, setExpandedSection] = useState(false);
  
//     const handleClick = (index) => {
//       if (activeIndexes.includes(index)) {
//         setActiveIndexes(activeIndexes.filter((i) => i !== index));
//         setExpandedSection(false);
//       } else {
//         setActiveIndexes([...activeIndexes, index]);
//         setExpandedSection(true);
//       }
//     };

const BlogPage3All = () => {

    const data = useStaticQuery(graphql`
    query MyQueryDigitalTransformation {
        text1: allContentfulTextBlock(filter: { id: { eq: "5d840505-adcf-50ac-aa35-8ca4c9936a07" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text2: allContentfulTextBlock(filter: { id: { eq: "0835d04c-5a44-544c-ace0-6d0195fb3b80" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text3: allContentfulTextBlock(filter: { id: { eq: "65d1fee8-05e1-5c2b-971d-eddbbebcc30a" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text4: allContentfulTextBlock(filter: { id: { eq: "abca2eae-c8b5-5fb5-8848-e1961f7c2e07" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text5: allContentfulTextBlock(filter: { id: { eq: "419de630-eed3-5ee1-8d11-acc05d347e20" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text6: allContentfulTextBlock(filter: { id: { eq: "6003bca5-b0cb-5697-954a-8f7d1106e086" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        pic1: allContentfulImageBlock(filter: { name: { eq: "Blog 3 Pic 1" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic2: allContentfulImageBlock(filter: { name: { eq: "Blog 3 Pic 2" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic3: allContentfulImageBlock(filter: { name: { eq: "Blog 3 Pic 3" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic4: allContentfulImageBlock(filter: { name: { eq: "Blog 3 Pic 4" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }


    }
  `);


  const t1 = data.text1.edges[0].node;
  const t2 = data.text2.edges[0].node;
  const t3 = data.text3.edges[0].node;
  const t4 = data.text4.edges[0].node;
  const t5 = data.text5.edges[0].node;
  const t6 = data.text6.edges[0].node;
  const p1 = data.pic1.edges[0].node;
  const p2 = data.pic2.edges[0].node;
  const p3 = data.pic3.edges[0].node;
  const p4 = data.pic4.edges[0].node;



  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };



    const [activeIndexes, setActiveIndexes] = useState([]);
  
    const handleClick = (index) => {
      if (activeIndexes.includes(index)) {
        setActiveIndexes(activeIndexes.filter((i) => i !== index));
      } else {
        setActiveIndexes([...activeIndexes, index]);
      }
    };





    const [activeSection, setActiveSection] = useState("");

    const sectionIds = [
      "Luxury Digital Transformation",
      "What is Luxury Digital Transformation?",
      "Agility Beats Profit",
      "Revlon - A Cautionary Tale",
      "Summary",
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const sectionOffsets = sectionIds.map((sectionId) => {
          const sectionElement = document.getElementById(sectionId);
          return {
            id: sectionId,
            offsetTop: sectionElement.offsetTop,
            offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
          };
        });
    
        const currentScrollPosition = window.scrollY + window.innerHeight / 2;
    
        for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
          if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
            setActiveSection(id);
            break;
          }
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);







    const [isContentsVisible, setIsContentsVisible] = useState(false);

    useEffect(() => {
      Aos.init({ duration: 2000 });
    
      const showThreshold = 500; // Adjust this value to determine when to show the contents
      const hideThreshold = 10000; // Adjust this value to determine when to hide the contents
      let isScrollingDown = false;
    
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
    
        if (scrollPosition > showThreshold && !isScrollingDown) {
          setIsContentsVisible(true);
          isScrollingDown = true;
        } else if (scrollPosition <= showThreshold && isScrollingDown) {
          setIsContentsVisible(false);
          isScrollingDown = false;
        }
    
        // Add logic to hide content near the bottom
        if (scrollPosition > hideThreshold) {
          setIsContentsVisible(false);
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handleArrowClick = () => {
      // Scroll down 300 pixels when the arrow is clicked
      window.scrollBy({
        top: 400,
        behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
      });
    };


  return (
    <>
      <Helmet>
        <title>Agility Not Profit Key to Success of Luxury Brands</title>
        <meta name="description" content="What is Luxury Digital Transformation? Discover why agility beats profit for luxury brands." />
        <meta name="keywords" content="what is luxury digital transformation, luxury digital transformation examples" />
        <h1>Agility Not Profit Key to Success of Luxury Brands</h1>
      </Helmet>
    <div className={`all1`}>
    <div className={`check1`}>



    {isContentsVisible && (
        <div>
          <div className="contentsListads">
            <p className="contentads">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                // href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}



      <div>
        <Helmet>
          <h2>Digital Transformation will be Death or Rebirth of Luxury Brands</h2>
          <h3>Luxury Ecommerce Grew at a faster rate in 2019</h3>
        </Helmet>
        <div id="Luxury Digital Transformation" className="contentFeatureBlogPage3Text11">
          <div className="textContainerBlogPage3Text11">
            <p className="titleBlogPage3Text11">{t1.title}</p>
            {t1.bodyText && (
              <div className="bodyBlogPage3Text11">
                {documentToReactComponents(JSON.parse(t1.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog1'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      <div>
        <div className="contentFeatureBlogPagePic11">
          <div className={"imageContainerBlogPagePic11"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury ecommerce"
              key={""}
              src={p1.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <Helmet>
          <h2>What is Luxury Digital Transformation?</h2>
        </Helmet>
        <div id="What is Luxury Digital Transformation?" className="contentFeatureBlogPage3Text22">
          <div className="textContainerBlogPage3Text22">
            <p className="titleBlogPage3Text22">{t2.title}</p>
            {t2.bodyText && (
              <div className="bodyBlogPage3Text22">
                {documentToReactComponents(JSON.parse(t2.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog2'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      <div>
        <div className="contentFeatureBlogPagePic22">
          <div className={"imageContainerBlogPagePic22"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury digital transformation examples"
              key={""}
              src={p2.image.image.file.url}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="contentFeatureQuoteBlogPage33">
          <div className="textContainerQuoteBlogPage33">
            <div className="openQuoteBlogPage33">“</div>
            <div className="quoteBodyBlogPage33">To power up e-commerce growth, the digital customer experience and behavioural insights are priorities for data and analytics according to fashion executives</div>
            <div className="closeQuoteBlogPage33">”</div>
            <img
              // data-aos="fade-up"
              className={"bofBlogPage33"}
              src={bof}
              alt="bof"
            />
          </div>
        </div>
      </div>

      <div>
        <Helmet>
          <h2>No Agility - No Profit</h2>
          <h3>For example China became the 2nd biggest luxury market in the world in 2023.</h3>
        </Helmet>
        <div id="Agility Beats Profit" className="contentFeatureBlogPage3Text33">
          <div className="textContainerBlogPage3Text33">
            <p className="titleBlogPage3Text33">{t3.title}</p>
            {t3.bodyText && (
              <div className="bodyBlogPage3Text33">
                {documentToReactComponents(JSON.parse(t3.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog3'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>



      <div>
        <div className="contentFeatureBlogPagePic33">
          <div className={"imageContainerBlogPagePic33"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="china luxury market"
              key={""}
              src={p3.image.image.file.url}
            />
          </div>
        </div>
      </div>

      <div>
        <Helmet>
          <h2>The Rise and Fall of Revlon - Billion Dollar Luxury Heritage Beauty</h2>
          <h3>Billion Dollar Heritage Beauty Leader</h3>
        </Helmet>
        <div className="contentFeatureBlogPage3Text44">
          <div className="textContainerBlogPage3Text44">
            {t4.bodyText && (
              <div className="bodyBlogPage3Text44">
                {documentToReactComponents(JSON.parse(t4.bodyText.raw))}
              </div>
            )}
            <p className="titleBlogPage3Text44">{t4.title}</p>
          </div>
          <div className={'arrowContainerBlog4'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      <div>
        <div className="contentFeatureBlogPagePic44">
          <div className={"imageContainerBlogPagePic44"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury digital transformation examples"
              key={""}
              src={p4.image.image.file.url}
            />
          </div>
        </div>
      </div>

      <div>
        <div id="Revlon - A Cautionary Tale" className="contentFeatureBlogPage3Text55">
          <div className="textContainerBlogPage3Text55">
            {t5.bodyText && (
              <div className="bodyBlogPage3Text55">
                {documentToReactComponents(JSON.parse(t5.bodyText.raw))}
              </div>
            )}
            <p className="titleBlogPage3Text55">{t5.title}</p>
          </div>
        </div>
      </div>

      <div>
        <Helmet>
          <h2>Listen and Adapt to Customers Needs or Pay the Price</h2>
          <h3>Don’t Underestimate the Ecommerce Pure Play Startups</h3>
          <h3>Listen and React Fast to Customer Behaviour</h3>
        </Helmet>
        <div id="Summary" className="contentFeatureBlogPage3Text66">
          <div className="textContainerBlogPage3Text66">
          <p className="titleBlogPage3Text66">{t6.title}</p>
            {t6.bodyText && (
              <div className="bodyBlogPage3Text66">
                {documentToReactComponents(JSON.parse(t6.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog5'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>





      
    </div>
    {/* <div style={{ marginTop: "30vw" }}>
        <NewsletterBar />
      </div> */}
{/*       
      <Footer /> */}
    </div>
    </>
  );
}

export default BlogPage3All;

