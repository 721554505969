import React, { useEffect, useRef, useState } from "react";
import "../scss/BlogPage3.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import FK from "./FK.png"; 
import email from "./email_black.png";
import leenanair from "./leena_nair.png";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import yemihead from "./yemi_head.png";

export default function BlogPage3() {
  const data = useStaticQuery(graphql`
  query MyQueryBlogPage3 {
    allContentfulHeroImage (filter:{id:{eq:"b9e233f1-43fd-58a7-89b6-1dcd390b0ba3"}}){
      edges {
        node {
          blogCategory
          blogTitle
          date
          readTime
          author {
            firstName
          }
          id
          socialMediaIcons {
            linkedInIcon {
              image {
                file {
                  url
                }
              }
            }
            facebookIcon {
              image {
                file {
                  url
                }
              }
            }
          }
          heroImage {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
}
  `);


  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const breakpoint = 2000; // Adjust this value to determine when the icons should disappear

      if (scrollPosition > breakpoint) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };
  

  const [isSocialMediaBlogVisible, setIsSocialMediaBlogVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 6500; // Adjust this value to determine when to hide socialMediaBlog

      if (scrollPosition > scrollThreshold) {
        setIsSocialMediaBlogVisible(false);
      } else {
        setIsSocialMediaBlogVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);











  // const [activeSection, setActiveSection] = useState("");

  // const sectionIds = [
  //   "1",
  //   "2",
  //   "3",
  //   "4",
  //   "5",
  // ];

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const sectionOffsets = sectionIds.map((sectionId) => {
  //       const sectionElement = document.getElementById(sectionId);
  //       return {
  //         id: sectionId,
  //         offsetTop: sectionElement.offsetTop,
  //         offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
  //       };
  //     });
  
  //     const currentScrollPosition = window.scrollY + window.innerHeight / 2;
  
  //     for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
  //       if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
  //         setActiveSection(id);
  //         break;
  //       }
  //     }
  //   };
  
  //   window.addEventListener("scroll", handleScroll);
  
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  

  



  // const [isContentsVisible, setIsContentsVisible] = useState(false);

  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  
  //   const showThreshold = 1100; // Adjust this value to determine when to show the contents
  //   const hideThreshold = 6500; // Adjust this value to determine when to hide the contents
  //   let isScrollingDown = false;
  
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  
  //     if (scrollPosition > showThreshold && !isScrollingDown) {
  //       setIsContentsVisible(true);
  //       isScrollingDown = true;
  //     } else if (scrollPosition <= showThreshold && isScrollingDown) {
  //       setIsContentsVisible(false);
  //       isScrollingDown = false;
  //     }
  
  //     // Add logic to hide content near the bottom
  //     if (scrollPosition > hideThreshold) {
  //       setIsContentsVisible(false);
  //     }
  //   };
  
  //   window.addEventListener("scroll", handleScroll);
  
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  











  
  return (
    <div className={`DT`}>
    <div className="contentFeatureBlogDT">
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
      </Helmet>


      <div className="SocialMediaBlog3" data-aos="fade-up">
      {isSocialMediaBlogVisible && (
          <>
            <div
              className="Share3"
            >
              Share
            </div>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/the-fresh-kid/">
              <img
                className="SocialIconBlog3"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
              />
            </a>

            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/the-fresh-kid/" target="_blank">
            <img
                className="SocialIconBlog3"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
              />
            </a>

            <a href="mailto:?subject=From thefreshkid.com: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry&body=Check out this article: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry www.thefreshkid.com/the-fresh-kid/" target="_blank">
              <img
                className="SocialIconBlog13"
                alt=""
                key=""
                src={email}
                style={{ marginRight: '100px' }} 
              />
            </a>
          </>
        )}
      </div>

      <div className="image-and-text-container">
        <div className="text-container">
            <p className={"categoryBlogDT"}>
            {data.allContentfulHeroImage.edges[0].node.blogCategory}
            </p>
            <p className={"lineDT1"}>______________</p>
            <p className={"titleBlogDT"}>
            {data.allContentfulHeroImage.edges[0].node.blogTitle}
            </p>
            <p className={"lineDT2"}>______________</p>

            
            <div className="digitalColumn">
              <div className={"digitalImage"}>
                <img
                  className={"bigImage"}
                  alt={""}
                  key={""}
                  src={
                    yemihead
                  }
                />
              </div>
              <div className={"digitalText"}>
                <p className={"authorBlogDT"}>
                {data.allContentfulHeroImage.edges[0].node.author.firstName}
                </p>
                <p className={"dateBlogDT"}>
                {data.allContentfulHeroImage.edges[0].node.date}
                </p>
                <p className={"readTimeBlogDT"}>
                {data.allContentfulHeroImage.edges[0].node.readTime}
                </p>
                </div>
            </div>
        </div>
        <div className="image-container">
            <img
            className={"imgLDT small-image"}
            src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}
            alt="what is luxury digital transformation"
            />
        </div>
        </div>

        

        {/* {isContentsVisible && (
        <div>
          <div className="contentsListb3">
            <p className="contentb3">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}

      <div id="1" className="nav1">1</div>
      <div id="2" className="nav2">2</div>
      <div id="3" className="nav3">3</div>
      <div id="4" className="nav4">4</div>
      <div id="5" className="nav5">5</div> */}




      {/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_sticky_social_bar */}


      </div>
      {/* <div style={{ marginTop: "300vw" }}>
        <NewsletterBar />
      </div>
      
      <Footer /> */}
    </div>
  );
}

