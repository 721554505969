import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutBlog from "../components/LayoutBlog";
import BlogPage3 from "../components/BlogPage/BlogPage3";
import BlogPage3All from "../components/BlogPage/BlogPage3All";
import BlogContents from '../components/BlogPage/BlogContents';

function Blog({ data }) {
  return (
    <LayoutBlog>
      <Helmet>
        <meta property="og:title" content="Agility Not Profit Key to Success of Luxury Brands" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/62KQ4MHKT05oR4TnoFrYdS/b8229bb7b56837af08263893d55008cf/TFK_Header_ACROBAT_1_.png" />
        <meta property="og:description" content="What is Luxury Digital Transformation? Discover why agility beats profit for luxury brands." />
        <meta property="og:url" content="https://thefreshkid.com/what-is-luxury-digitaltransformation/" />
      </Helmet>

      <BlogPage3 />
      <BlogPage3All />
      <BlogContents />
    </LayoutBlog>
  );
}

export default Blog;
